import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Newsletter from '../components/Newsletter';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';
import AboutImages from '../components/AboutImages';
import Helmet from 'react-helmet';

function ConfirmLanding () {
  const aboutImages = AboutImages ();
  const screenshot = useStaticQuery (
    graphql`
      query {
        confirm:file(relativePath: {eq: "newsletter/email.png"}) {
          childImageSharp {
            fluid(maxWidth: 800, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
              originalName
              originalImg
            }
          }
        }
        gmail:file(relativePath: {eq: "newsletter/mail-gmail-icon.png"}) {
          childImageSharp {
            fixed(width: 80, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp
              originalName
            }
          }
        }
        hotmail:file(relativePath: {eq: "newsletter/mail-hotmail-icon.png"}) {
          childImageSharp {
            fixed(width: 80, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp
              originalName
            }
          }
        }
        yahoo:file(relativePath: {eq: "newsletter/yahoo-icon.png"}) {
          childImageSharp {
            fixed(width: 80, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp
              originalName
            }
          }
        }
      }`
  );
  return (
    <Layout>
      <SEO
        keywords={[`About`, 'Diego Valle', 'Diego Valle-Jones']}
        title="Confirm your subscription"
        description="Please confirm your subscription"
      />
      <Helmet>
        <meta name="robots" content="noindex, nofollow, noarchive" />
      </Helmet>
      <main className="flex flex-col flex-1 md:justify-center max-w-4xl mx-auto px-4 py-8 md:p-8 w-full">

        <h1 className="text-center">
          Hang on! Just
          {' '}
          <span className="text-red-400">one thing left</span>
          {' '}
          to do...
        </h1>
        <h3 className="text-center">
          I don't want to spam anyone, so I have to be sure you’re committed.
        </h3>

        <section className="p-4">
          <div className="flex flex-wrap text-center sm:justify-center md:justify-center">
            <div className="md:w-1/2 py-4 md:px-10 mb-4 md:mb-0 ">

              <div className="non-semantic-protector">
                <h3 className="text-2xl mb-3 font-heading ribbon">
                  STEP <span className="text-3xl">1</span>
                </h3>
              </div>

              <p className="text-gray-900 leading-relaxed"> Check your email</p>
              <a href="https://mail.google.com/">
                <Img
                  fixed={screenshot.gmail.childImageSharp.fixed}
                  loading="lazy"
                />
              </a>
              <a href="https://www.live.com/">
                <Img
                  fixed={screenshot.hotmail.childImageSharp.fixed}
                  loading="lazy"
                />
              </a>
              <a href="https://mail.yahoo.com/">
                <Img
                  fixed={screenshot.yahoo.childImageSharp.fixed}
                  loading="lazy"
                />
              </a>
            </div>
            <div className="md:w-1/2 py-4 md:px-10 mb-4 md:mb-0 md:border-l">
              <div className="non-semantic-protector">
                <h3 className="text-2xl mb-3 font-heading ribbon">
                  STEP <span className="text-3xl">2</span>
                </h3>
              </div>
              <p className="text-gray-900 leading-relaxed">
                Find the one from{' '}
                <i>Diego Valle-Jones</i>{' '}
                <b>diego@diegovalle.net</b>
                <a href="https://mail.yahoo.com/" />
              </p>
            </div>
          </div>
        </section>

        <section className="p-4">
          <div className="flex flex-wrap text-center sm:justify-center md:justify-center">
            <div className="md:w-1/2 py-4 md:px-10 mb-4 md:mb-0">

              <div className="non-semantic-protector">
                <h3 className="text-2xl mb-3 font-heading ribbon">
                  STEP <span className="text-3xl">3</span>
                </h3>
              </div>
              <p className="text-gray-900 leading-relaxed">
                {' '}Click on the
                confirmation link
              </p>
              <Img
                fluid={screenshot.confirm.childImageSharp.fluid}
                sizes={{
                  ...screenshot.confirm.childImageSharp.fluid,
                  aspectRatio: 800 / 600,
                }}
                loading="lazy"
              />
            </div>
            <div className="md:w-1/2 py-4 md:px-10 mb-4 md:mb-0 md:border-l">
              <div className="non-semantic-protector">
                <h3 className="text-2xl mb-3 font-heading ribbon">
                  STEP <span className="text-3xl">4</span>
                </h3>
              </div>
              <p className="text-gray-900 leading-relaxed">
                Get All The Shapefiles
                That I Just
                Sent To Your Inbox
              </p>
            </div>
          </div>
        </section>

        <h2 className="text-center">
          Finally, follow me on
          {' '}
          <a href="https://twitter.com/diegovalle">twitter</a>
        </h2>

        <p className="text-center">
          <a href="https://gist.github.com/diegovalle/0ec566ad6f6b47dd450c">
            Privacy Policy
          </a>
        </p>
      </main>
    </Layout>
  );
}

export default ConfirmLanding;
